import { type AppModule } from '~/types'
import { AnalyticsBrowser } from '@segment/analytics-next'

const analytics = new AnalyticsBrowser()

export const segmentEnabled = ref(
  import.meta.env.VITE_APP_SEGMENT_ENABLED === '1' && !import.meta.env.SSR
)

export const install: AppModule = ({ isClient }) => {
  if (segmentEnabled.value && isClient) {
    console.log('Segment enabled')
    analytics
      .load({ writeKey: import.meta.env.VITE_APP_SEGMENT_KEY })
      .catch((err) => (segmentEnabled.value = false))

    window.analytics = analytics
  }
}

export const $analytics = analytics
