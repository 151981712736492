import DOMPurify from 'dompurify'
import { useMemberFetch } from '~/services/member.service'
import type { siteLinkType, contactFormType } from '~/types'
import statesList from './statesList.json'

import mockContactUsDetails from '../../mocks/contactUsDetails.json'
import mockTermsMetadata from '../../mocks/termsMetadata.json'

import dashboardSvg from '~/images/icons/dashboard.svg?raw'
import reportSvg from '~/images/icons/report.svg?raw'
import searchSvg from '~/images/icons/search.svg?raw'
import teamSvg from '~/images/icons/team.svg?raw'
import creditcardSvg from '~/images/icons/creditcard.svg?raw'

export const useSiteStore = defineStore('site', () => {
  const loading = ref(false)
  const collapsedMenu = ref(false)
  const passwordlessCode = ref('')
  const localeReady = ref(false)
  const siteMessage = ref({
    error: '',
    success: '',
    info: '',
  })

  const sandboxMode = ref(import.meta.env.MODE !== 'prod')

  const companyName = ref('')
  const parentCompanyName = ref('')
  const customerSupportPhone = ref('')
  const customerSupportHours = ref('')
  const insuranceClaimsPhoneNumber = ref('(877) 208-5412')
  const contactFormSubjects = ref<string[]>([])
  const siteTerms = ref('')

  const siteLinks = computed(() => memberRoutes.value)

  const loginUrl = computed(
    () =>
      `${import.meta.env.VITE_APP_FUSIONAUTH_URL}/oauth2/authorize/?client_id=${
        import.meta.env.VITE_APP_FUSIONAUTH_CLIENTID
      }&redirect_uri=${
        import.meta.env.VITE_APP_TLS_URL
      }/auth&response_type=code&scope=openid offline_access`
  )

  const editAccountUrl = computed(
    () =>
      `${import.meta.env.VITE_APP_FUSIONAUTH_URL}/account/edit?client_id=${
        import.meta.env.VITE_APP_FUSIONAUTH_CLIENTID
      }`
  )

  const logoutUrl = computed(
    () =>
      `${import.meta.env.VITE_APP_FUSIONAUTH_URL}/oauth2/logout?client_id=${
        import.meta.env.VITE_APP_FUSIONAUTH_CLIENTID
      }&scope=openid offline_access`
  )

  const accountLogoutUrl = computed(
    () =>
      `${import.meta.env.VITE_APP_FUSIONAUTH_URL}/account/logout?client_id=${
        import.meta.env.VITE_APP_FUSIONAUTH_CLIENTID
      }`
  )

  const siteErrorMessage = computed(() => siteMessage.value?.error)
  const siteSuccessMessage = computed(() => siteMessage.value?.success)
  const siteInfoMessage = computed(() => siteMessage.value?.info)

  const signupSteps: string[] = [
    'AccountStep',
    'PersonalStep',
    'IdentityStep',
    'BillingStep',
    'ConfirmationStep',
  ]

  const memberRoutes = computed((): siteLinkType[] => {
    const creditStore = useCreditStore()
    const userStore = useUserStore()
    const { memberPlan } = storeToRefs(userStore)

    return [
      {
        title: {
          desktop: 'menus.home',
        },
        link: 'member-home',
        icon: dashboardSvg,
      },
      {
        title: {
          desktop: 'menus.3bReport',
        },
        link: creditStore.lastViewedReport,
        linkMatch: ['3b-classic-report', '3b-smart-report'],
        icon: reportSvg,
        children: [
          {
            title: {
              desktop: 'menus.personalInformation',
            },
            link: '#personal-information',
          },
          {
            title: {
              desktop: 'menus.summary',
            },
            link: '#summary',
          },
          {
            title: {
              desktop: 'menus.accountHistory',
            },
            link: '#account-history',
          },
          {
            title: {
              desktop: 'menus.publicRecords',
            },
            link: '#public-records',
          },
          {
            title: {
              desktop: 'menus.inquiries',
            },
            link: '#inquiries',
          },
          {
            title: {
              desktop: 'menus.creditorContacts',
            },
            link: '#creditor-contacts',
          },
        ],
      },
      {
        title: {
          desktop: 'menus.mylonaOffers',
          mobile: 'menus.offers',
        },
        link: 'mylona',
        icon: creditcardSvg,
      },
      {
        title: {
          desktop: 'menus.privacy',
        },
        icon: searchSvg,
        link: 'privacy',
        ...(memberPlan.value?.hasDataBreachMonitoring && {
          linkMatch: ['privacy', 'data-breach-monitoring'],
          passthroughActive: true,
          children: [
            {
              title: {
                desktop: 'menus.dataBrokerRemovals',
              },
              link: 'privacy',
            },
            {
              title: {
                desktop: 'menus.dataBreachDarkWeb',
              },
              link: 'data-breach-monitoring',
            },
          ],
        }),
      },
      {
        title: {
          desktop: 'menus.1MillionFraudInsurance',
          mobile: 'menus.fraud',
        },
        link: 'fraud',
        linkMatch: ['fraud', 'file-claim'],
        icon: teamSvg,
      },
    ]
  })

  const profileLinks: siteLinkType[] = [
    {
      title: {
        desktop: 'menus.accountSettings',
      },
      link: 'account-settings',
    },
    {
      title: {
        desktop: 'menus.contactUs',
      },
      link: 'member-contact',
    },
  ]

  const membershipLinks = [
    {
      title: 'member.settings.updatePaymentMethod',
      link: 'payment-settings',
    },
    {
      title: 'member.settings.viewBillingHistory',
      link: 'billing-history',
    },
    {
      title: 'member.settings.cancelMembership',
      link: 'cancel-membership',
    },
  ]

  const mylonaWidgets = <any>{
    'credit-cards': {
      affId: '1739',
      fileId: '672',
      offerId: '1692',
    },
    'personal-loans': {
      affId: '1739',
      fileId: '674',
      offerId: '1658',
    },
    'auto-loans': {
      affId: '1739',
      fileId: '642',
      offerId: '1713',
    },
    'home-loans': {
      affId: '1739',
      fileId: '670',
      offerId: '1685',
    },
    'student-loans': {
      affId: '1739',
      fileId: '685',
      offerId: '1729',
    },
    'auto-refinance': {
      affId: '1739',
      fileId: '641',
      offerId: '1728',
    },
    'business-credit-cards': {
      affId: '1739',
      fileId: '645',
      offerId: '1691',
    },
    'business-loans': {
      affId: '1739',
      fileId: '644',
      offerId: '1715',
    },
    'checking-account': {
      affId: '1739',
      fileId: '725',
      offerId: '2354',
    },
    'savings-account': {
      affId: '1739',
      fileId: '680',
      offerId: '1690',
    },
  }

  const setError = (msg: string) => {
    if (!msg) return
    siteMessage.value.error = msg
  }

  const setSuccess = (msg: string) => {
    if (!msg) return
    siteMessage.value.success = msg
  }

  const setInfo = (msg: string) => {
    if (!msg) return
    siteMessage.value.info = msg
  }

  const clearMessages = () => {
    siteMessage.value = {
      error: '',
      success: '',
      info: '',
    }
  }

  const getContactFormDetails = async () => {
    if (Object.keys(contactFormSubjects.value).length) return

    const userStore = useUserStore()
    const authStore = useAuthStore()

    if (authStore.userAuthenticated && !userStore.user) {
      await userStore.getUser()
    }

    if (import.meta.env.VITE_APP_USE_MOCK === 'true') {
      console.log('Fetching contact form details')
      customerSupportHours.value = mockContactUsDetails.supportHours
      customerSupportPhone.value = mockContactUsDetails.supportPhone
      contactFormSubjects.value = mockContactUsDetails.subjects.reduce(
        (a, v) => ({ ...a, [v]: v }),
        {}
      )
      console.log('Contact form details fetched')
      return true
    }

    try {
      const { error, data } = await useMemberFetch(
        `site/contact-us?pid=${userStore.userPID}`
      )

      if (data.value && !error.value) {
        customerSupportHours.value = data.value?.supportHours
        customerSupportPhone.value = data.value?.supportPhone

        contactFormSubjects.value = data.value?.subjects.reduce(
          (a: object, v: string) => ({ ...a, [v]: v }),
          {}
        )
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }

  /**
   *
   * @param payload form payload
   * @param bypass bypass contact endpoint and open email client. Default is true until contact endpoint is ready
   * @returns  success boolean
   */
  const submitContactForm = async (
    payload: contactFormType,
    bypass: boolean
  ) => {
    return new Promise((resolve) => {
      const authStore = useAuthStore()
      const userStore = useUserStore()

      Object.keys(payload).forEach((key) => {
        payload[key] = DOMPurify.sanitize(payload[key])
      })

      if (bypass) {
        window.open(
          `mailto:support@thelendingscore.com?subject=Support Request: ${payload.subject}${userStore.userCustomerToken ? ` - ${userStore.userCustomerToken}` : ''}&body=Name: ${payload.firstName} ${payload.lastName}%0D%0AEmail: ${payload.email}%0D%0APhone: ${payload.phone}%0D%0AMessage: ${payload.body}%0D%0A`
        )

        resolve(true)
      } else {
        try {
          const { onFetchError, onFetchResponse } = useMemberFetch(
            `${authStore.userAuthenticated ? 'customer' : 'site'}/contact-us`
          ).post(payload)

          onFetchResponse(() => {
            resolve(true)
          })

          onFetchError(() => {
            resolve(false)
          })
        } catch (error) {
          resolve(false)
        }
      }
    })
  }

  const getProductTerms = async () => {
    if (siteTerms.value.length) return

    if (import.meta.env.VITE_APP_USE_MOCK === 'true') {
      siteTerms.value = mockTermsMetadata.cobrand.termsAndConditions

      companyName.value = mockTermsMetadata.companyName
      parentCompanyName.value = mockTermsMetadata.parentCompanyName
      return
    }

    const userStore = useUserStore()

    try {
      const { error, data } = await useMemberFetch(
        `site/terms-metadata?pid=${userStore.userPID}`
      )

      if (data.value && !error.value) {
        if (data.value?.cobrand?.termsAndConditions)
          siteTerms.value = data.value.cobrand.termsAndConditions

        companyName.value = data.value?.companyName
        parentCompanyName.value = data.value?.parentCompanyName

        return true
      }

      return false
    } catch (error) {
      return false
    }
  }

  const toggleTheme = () => {
    document.getElementsByTagName('html')[0].classList.toggle('dark')
  }

  const waitTimer = async (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms))

  watch(
    [
      () => siteMessage.value.error,
      () => siteMessage.value.success,
      () => siteMessage.value.info,
    ],
    () => {
      if (
        siteErrorMessage.value.length === 0 &&
        siteSuccessMessage.value.length === 0 &&
        siteInfoMessage.value.length === 0
      )
        return

      setTimeout(() => {
        clearMessages()
      }, 6000)
    }
  )

  const $reset = () => {
    loading.value = false
    clearMessages()
  }

  return {
    collapsedMenu,
    sandboxMode,
    statesList,
    localeReady,
    companyName,
    parentCompanyName,
    siteTerms,
    customerSupportPhone,
    customerSupportHours,
    insuranceClaimsPhoneNumber,
    contactFormSubjects,
    passwordlessCode,
    editAccountUrl,
    loginUrl,
    logoutUrl,
    accountLogoutUrl,
    signupSteps,
    loading,
    siteLinks,
    profileLinks,
    membershipLinks,
    mylonaWidgets,
    siteErrorMessage,
    siteSuccessMessage,
    siteInfoMessage,
    toggleTheme,
    setError,
    setSuccess,
    setInfo,
    clearMessages,
    getContactFormDetails,
    submitContactForm,
    getProductTerms,
    waitTimer,
    $reset,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useSiteStore, import.meta.hot))
